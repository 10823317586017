import { PERMISSION, STORAGE_KEY, TV_MODELS } from "../../utils/constants";
import { dispatchNotAvailableEvent, EVENTS } from "../../utils/eventsConst";
import { Logger } from "../../utils/logger";
import { SESSION } from "../../utils/session";
import { addTimestampToPath, inPreview } from "../../utils/utils";
import Query from "../query";
import { Media } from "./media";

export const STB = {
    alarmVolume: 16,
    supportBlur: true,
    supportHA: true,
    supportBluetooth: true,
    supportAnimations: true,
    builtInCC: false,
    forceMovieLanguage: false,
    navigateOnCCInstructions: true,
    model: "",
    ref: null,
    init() {
        console.log("Die template");
    },
    getMac(onSuccess) {
        console.log("Die template");
        if (onSuccess) {
            onSuccess();
        }
    },
    renewToken(newToken) {
        console.log("Die template");
    },
    reload() {
        window.location = window.location.origin + window.location.search;
    },
    cleanCache() {
        //get lang to preserve this setup after clean cache
        const lang = localStorage.getItem("lang");
        const mac = localStorage.getItem("mac");

        localStorage.clear();
        sessionStorage.clear();

        localStorage.setItem("lang", lang);
        localStorage.setItem("mac", mac);

        if (typeof caches !== "undefined" && "keys" in caches) {
            caches
                .keys()
                .then(function (cacheNames) {
                    cacheNames.forEach(function (cacheName) {
                        caches.delete(cacheName).then(function (success) {
                            if (success) {
                                console.log(`Cache "${cacheName}" eliminado.`);
                            } else {
                                console.log(`No se pudo eliminar el cache "${cacheName}".`);
                            }
                        });
                    });
                })
                .catch(function (error) {
                    console.error("Ocurrió un error al gestionar las cachés:", error);
                });
        } else {
            console.error("La API de caché no está disponible en este navegador.");
        }
    },
    fullReload(params) {
        if (params?.randomMinutesToExecute) {
            // Calcular un tiempo aleatorio entre 0 y params?.randomMinutesToExecute minutos (en milisegundos)
            const randomTime = Math.random() * (params?.randomMinutesToExecute * 60 * 1000);

            setTimeout(() => {
                STB.cleanCache();
                Logger.remoteLog("FULL RELOAD EXECUTED");
                window.location = addTimestampToPath(window.location.origin + window.location.search);
            }, randomTime);
        } else {
            STB.cleanCache();
            Logger.remoteLog("FULL RELOAD EXECUTED");
            window.location = addTimestampToPath(window.location.origin + window.location.search);
        }
    },
    powerOn() {
        console.log("Power ON");
    },
    powerOff() {
        console.log("Power OFF");
    },
    updatePowerState() {
        console.log("Check power state");
    },
    actionsOnPowerOn() {
        if (SESSION.isCommonZone()) {
            this.reload();
        } else {
            Media.replayLastVideo();
        }
    },
    powerKey() {
        console.log("Power key press");
    },
    gotoOfflineMode() {
        Logger.remoteLog("Load offline mode");
        this._gotoOfflineMode();
    },
    _gotoOfflineMode() {
        console.log("Die template");
    },
    setVolume(volume) {
        console.log(`Set volume to ${volume}`);
    },
    changeToInput(input) {
        console.log(`Change to input ${input}`);
    },
    backToInterface() {
        console.log("Load interface");
    },
    ensureZafiroInterface() {
        if (sessionStorage.getItem("outOfInterface") === "true") {
            sessionStorage.removeItem("outOfInterface");
            this.backToInterface();
        }
    },
    checkout() {
        localStorage.removeItem("lastStayGuestId");
        this._checkout();
    },
    _checkout() {
        console.log("Checkout actions");
    },
    launchApp(appName) {
        if (SESSION.hasPermission(PERMISSION.APPS)) {
            Media.stop();
            if (this.hasGoogleTVConnected()) {
                this.launchCCApp(appName);
            } else {
                this._launchApp(appName);
            }
        } else {
            dispatchNotAvailableEvent();
        }
    },
    launchCCApp(appName) {
        if (inPreview()) {
            dispatchNotAvailableEvent();
            return;
        }
        //TODO: COMPROBAR SI LA APP EXISTE SI NO => MOSTRAR MENSAJE DE NO DISPONIBLE
        Query({
            query: `
                mutation{
                    sendMqttActionToCC(ccRef:"${SESSION.getChromecastRef()}" action:"launchApp" actionData:"{\"appName\":\"${appName}\"}"){
                        ok
                        error{
                            code
                            msg
                        }
                    }
                }
          `,
            propagateError: false,
            onResponse(res) {
                sessionStorage.setItem("onCCView", true);
                setTimeout(function () {
                    STB.changeToInput(SESSION.getChromecastInput());
                }, 1000);
            },
        });
    },
    _launchApp(appName) {
        console.log(`Launch app ${appName}`);
        dispatchNotAvailableEvent();
    },
    pong() {
        console.log("Still alive");
    },
    checkServerInfo() {
        let e = new CustomEvent(EVENTS.type.GLOBAL, {
            detail: EVENTS.GLOBAL.CHECK_SERVER_INFO,
        });
        document.dispatchEvent(e);
    },
    checkServerInfoViaMQTT() {
        let e = new CustomEvent(EVENTS.type.GLOBAL, {
            detail: EVENTS.GLOBAL.CHECK_STATUS_VIA_MQTT,
        });
        document.dispatchEvent(e);
    },
    getDeviceStatus() {
        console.log("Die template");
    },
    sendCurrentLayoutInfo(cLayout) {
        console.log("Current layout: " + cLayout);
    },
    onKeyReceived(e, simulateKeyPress) {
        console.log("Die template");
    },
    saveDataForHA(localKey, dataToStore) {
        console.log("Die template");
    },
    checkIn() {
        console.log("Die template");
    },
    ensurePMSSession(currentGuestId) {
        console.log("Die template");
    },
    changeTVName(name) {
        console.log("Die template");
    },
    checkOnLine() {
        console.log("Die template");
    },
    launchUSB() {
        console.log("Die template");
    },
    noBlueToothApp() {
        console.log("Die template");
    },
    launchBluetooth() {
        console.log("Die template");
    },
    initializeCastServices(castingGatewayIP, airserverAccountCode) {
        console.log("Die template");
    },
    resetCredentials() {
        console.log("Die template");
    },
    resetCredentialsEnd(params) {
        const deletingCredentials = localStorage.getItem("deletingCredentialsOnMultipleDevices");

        if (params?.fail && !deletingCredentials) {
            let e = new CustomEvent(EVENTS.type.POPUP, {
                detail: { type: EVENTS.POPUP.CUSTOM_TEXT, text: "reset-credentials-error" },
            });
            document.dispatchEvent(e);
        } else if (deletingCredentials) {
            Query({
                query: `
                    mutation{
                        resetCredentialsCompleted{
                            ok
                            allCompleted
                            error{
                                code
                                msg
                            }
                        }
                    }
              `,
                propagateError: false,
                onResponse(res) {
                    if (
                        res?.data?.resetCredentialsCompleted?.ok &&
                        res?.data?.resetCredentialsCompleted?.allCompleted
                    ) {
                        localStorage.removeItem("deletingCredentialsOnMultipleDevices");
                        let e = new CustomEvent(EVENTS.type.POPUP, {
                            detail: {
                                type: EVENTS.POPUP.CUSTOM_TEXT,
                                text: "reset-successful-msg",
                            },
                        });
                        document.dispatchEvent(e);
                    }
                },
            });
        }
    },
    _resetCredentialsOnPowerOff() {
        console.log("Die template");
    },
    showCC() {
        console.log("Die template");
    },
    hasGoogleTVConnected() {
        return (
            this.model !== TV_MODELS.GOOGLE_TV && SESSION?.tvSession?.roomInfo?.chromecastModel === TV_MODELS.GOOGLE_TV
        );
    },
    isGoogleTV() {
        return false;
    },
    useStreamSubtitles() {
        return false;
    },
    slowProcessDevice() {
        return false;
    },
};
