import { STATE, STORAGE_KEY, TV_MODELS } from "../../../utils/constants";
import { prepareDeviceKeyMap } from "../../../utils/utils";
import { keyMap } from "./keyMap";

/* eslint-disable no-undef */
export const STB = {
    model: TV_MODELS.GOOGLE_TV,
    preventReloadOnCheckout: true,
    ref: ``,
    videoOnBack: true,
    supportVideoLoop: false,
    supportHA: true,
    supportMQTT: true,
    autoSetLangOnVideoPlay: true,
    noKeyUpSupport: true,

    init() {
        this.keyMap = prepareDeviceKeyMap(keyMap);
        console.log("Init GoogleTV api");
        try {
            this.ip = Android.getIP();
        } catch (error) {
            return null;
        }

        if (typeof window !== "undefined") {
            window.checkServerInfo = function () {
                this.checkServerInfo();
            }.bind(this);

            window.onCheckStatus = function () {
                this.checkServerInfoViaMQTT();
            }.bind(this);
        }
    },
    getMac(onSuccess) {
        if (!this.ref) {
            this.mac = Android.getMAC();
            this.ref = `${this.model}-${Android.getRef()}`;
        }
        if (onSuccess) {
            onSuccess();
        }
    },
    isGoogleTV() {
        return true;
    },
    updatePowerState() {
        let isOn = Android.isTvOn();
        localStorage.setItem(STORAGE_KEY.POWER_STATE, isOn ? STATE.ONLINE : STATE.STANDBY);
    },
    fullReload() {
        Android.fullReload();
    },
    powerOn() {
        Android.powerOn();
    },
    _checkout() {
        Android.checkout();
    },
    resetCredentials() {
        Android.checkout();
    },
    _launchApp(appName) {
        Android.launchApp(appName);
    },
    pong() {
        console.log("PONG");
        return "PONG";
    },
    getDeviceStatus() {
        return Android.getCheckStatus();
    },
    sendCurrentLayoutInfo(cLayout) {
        return Android.onUserAction(cLayout);
    },
    saveDataForHA(localKey, dataToStore) {
        try {
            Android.saveHAKeyInfo(localKey, JSON.stringify(dataToStore));
            this.supportHA = true;
        } catch (e) {
            console.log("HA unsupported", e);
            this.supportHA = false;
        }
    },
    _gotoOfflineMode() {
        try {
            Android.loadHAURL();
        } catch (e) {
            console.log("HA unsupported", e);
        }
    },
    renewToken(newToken) {
        Android.tokenChanged(newToken);
    },
    useStreamSubtitles() {
        return false;
    },
};
