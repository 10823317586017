import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Button from "../common/button";
import Calculator from "../common/calculator";
import Modal from "../common/modal";
import focus from "../../utils/focus";
import { Texts } from "../../utils/texts";
import { setNumpadCode } from "../../actions/eventsActions";
import { setPopUp } from "../../actions/uiActions";
import Loading from "../loading";
import { figmapx2vw, getStayGuestRoomId, limitDecimals, padZero, printPrice } from "../../utils/utils";
import { useLocation } from "wouter";
import { SESSION } from "../../utils/session";
import { MOVIES } from "../../utils/constants";
import Query from "../../hooks/query";
import { printRentaToText } from "../../utils/movies";
import { rentMovie } from "../../hooks/GraphqlCalls/movies/rentMovie";

const MovieRent = () => {
    const params = useLocation();
    const dispatch = useDispatch();
    const CURRENCY_CODE = useSelector((state) => state.status.locationData.projectInfo.currency);

    const [error, setError] = useState("");
    const [orderSuccess, setSuccess] = useState(false);
    const [orderProcessing, setOrderProcessing] = useState(false);
    const [step, setStep] = useState(1);

    const numpadCode = useSelector((state) => state.events.numpadCode);
    const texts = useSelector((state) => state.ui.texts);
    const locationData = useSelector((state) => state.status.locationData);

    const { sessionData } = useSelector((state) => state.status);
    const stayGuestRoomsId = getStayGuestRoomId(sessionData);

    const parentalCode = sessionStorage.getItem("parentalCode");

    const printTaxLabel = () => {
        return texts[`Tax ${SESSION.cMovie.pricesWithTaxes ? "included" : "excluded"}`];
    };

    const printMoviePrice = () => {
        return printPrice(SESSION.cMovie.priceToShow, CURRENCY_CODE);
    };

    const composeTotalSubtitle = () => {
        let deliveryCost,
            totalCost = 0;
        totalCost = limitDecimals(3.4);

        return (
            <>
                {Texts.capitalize(Texts.translate(texts, "rent-for").replace("{{price}}", printMoviePrice()))}{" "}
                <span style={{ textTransform: "lowercase" }}>({printTaxLabel()})</span>
                <div className={"bold pt-4"}>
                    {Texts.translate(texts, parentalCode ? "add-parental-code" : "Add your room number")}
                </div>
            </>
        );
    };

    const makeOrder = () => {
        rentMovie(
            parseInt(stayGuestRoomsId),
            parseInt(SESSION.cMovie?.id),
            () => {
                setSuccess(true);
                setError("");
                SESSION.refreshMovieData = true;
                dispatch(setNumpadCode(null));
            },
            () => {
                setOrderProcessing(false);
                setError(Texts.translate(texts, "order-error"));
            },
        );
    };
    return step === 1 ? (
        <Modal
            title={Texts.translate(texts, "rent-x").replace("{{item}}", SESSION.cMovie?.name)}
            firstFocus={"btn-continue"}
            backBtn={true}
            width="69.583vw"
            height="62.222vh"
            backAction={() => {
                dispatch(setPopUp(null));
            }}
            body={
                <>
                    <div className={"text-center"} style={{ lineHeight: "7.4vh", fontSize: `${figmapx2vw(32)}` }}>
                        {ReactHtmlParser(
                            Texts.translate(texts, "rent-movie-text")
                                .replace("{{price}}", printMoviePrice())
                                .replace("{{taxLabel}}", printTaxLabel())
                                .replace("{{hours}}", MOVIES.RENTHOURS),
                        )}
                    </div>
                    <div style={{ marginLeft: "10vw", marginRight: "10vw", marginTop: "20vh" }}>
                        <Button
                            insideModal={true}
                            data={{
                                id: "continue",
                                name: Texts.capitalize(texts["continue"]),
                                customClass: "text-center py-2 float-left text-2xl",
                                customStyle: { width: "100%" },
                                border: true,
                                onClick: () => {
                                    setStep(2);
                                },
                            }}
                        />
                    </div>
                </>
            }
        />
    ) : step === 2 && orderSuccess ? (
        <Modal
            title={Texts.translate(texts, "rental-confirmation")}
            titleClass={"mt-6 pt-8"}
            width="69.583vw"
            height="62.222vh"
            firstFocus={"btn-watch"}
            body={
                <>
                    <div
                        style={{ height: "20vh", marginTop: "2vh", lineHeight: "7.4vh" }}
                        className={"text-2xl text-center"}
                    >
                        {ReactHtmlParser(printRentaToText(texts))}
                    </div>
                    <div className="py-8 mx-auto table " style={{ width: "80%", marginTop: "12vh" }}>
                        <Button
                            insideModal={true}
                            data={{
                                id: "watch",
                                name: Texts.translate(texts, "watch-movie"),
                                customClass: "text-center py-2 w-full float-left text-2xl",
                                customStyle: { width: "48%" },
                                border: true,
                                onClick: () => {
                                    sessionStorage.setItem("forceFocus", "btn-status");
                                    SESSION.playMovie = true;
                                    dispatch(setPopUp(null));
                                },
                            }}
                        />
                        <Button
                            insideModal={true}
                            data={{
                                id: "close",
                                name: Texts.capitalize(texts["close"]),
                                customClass: "text-center py-2  float-left  w-full text-2xl",
                                customStyle: { width: "48%", marginLeft: "4%" },
                                border: true,
                                onClick: () => {
                                    dispatch(setPopUp(null));
                                },
                            }}
                        />
                    </div>
                </>
            }
        />
    ) : step === 2 ? (
        <Modal
            title={Texts.translate(texts, "Finalise rent")}
            subtitle={composeTotalSubtitle()}
            titleClass={"pt-6"}
            backBtn={true}
            cancelBtn={true}
            preventCloseOnBack={true}
            backAction={() => setStep(1)}
            body={
                <>
                    {orderProcessing ? (
                        <div className={"relative text-2xl text-center w-full bg-white"} style={{ height: "29vh" }}>
                            <div className={"table w-full h-full"}>
                                <div className={"table-cell text-center vertical-middle w-full h-full text-center"}>
                                    {ReactHtmlParser(Texts.translate(texts, "order-processing"))}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Calculator
                            code={parentalCode || locationData.roomInfo.number}
                            encrypt={parentalCode}
                            confirmText={texts["Finalize"]}
                            confirmIcon="room"
                            error={error}
                            confirmAction={(code) => {
                                if (code === (parentalCode || locationData.roomInfo.number)) {
                                    setOrderProcessing(true);
                                    makeOrder();
                                } else {
                                    setError(
                                        Texts.translate(
                                            texts,
                                            parentalCode ? "wrong parental code" : "Wrong room number",
                                        ) + "!",
                                    );
                                }
                            }}
                            setError={setError}
                        />
                    )}
                </>
            }
        />
    ) : null;
};

export default MovieRent;
