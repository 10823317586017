import {
    SET_INSTALLED,
    SET_SERVER_ONLINE,
    SET_APP_INITIALIZED,
    SET_LOCATION_DATA,
    SET_SESSION_DATA,
    SET_FONTS_READY,
    SET_LIBRARIES_READY,
    SET_MESSAGE_READ,
    ADD_FAVOURITE,
    SET_WELCOMED,
    DELETE_FAVOURITE,
    SET_ALARM,
    UPDATE_STATUS,
    STATUS_UPDATE_VIA_MQTT,
    CONNECTION_ERROR,
    SET_TV_APP_OUTDATED,
    MESSAGES_NOT_SHOWN,
    SET_MESSAGES,
    UPDATE_POWER_STATUS,
    CENTER_FULL_SCREEN_MESSAGE,
} from "../actions/statusActions";
import { STB } from "../hooks/apis/stb";
import Query from "../hooks/query";
import { Logger } from "../utils/logger";
import { SESSION } from "../utils/session";
import storage from "../utils/storage";
import { inPreview, removeArrayItem } from "../utils/utils";

const initialState = {
    initialized: false,
    installed: false,
    locationData: null,
    sessionData: null,
    serverOnline: false,
    fontsReady: true,
    librariesReady: false,
    unreadMessages: null,
    connectionError: false,
    messagesNotShown: false,
    centerFullScreenMsg: false,
};

export default function (state = initialState, action) {
    let unreadMsgs = 0;
    switch (action.type) {
        case SET_INSTALLED:
            return {
                ...state,
                installed: action.payload,
            };
        case SET_SERVER_ONLINE:
            return {
                ...state,
                serverOnline: action.payload,
            };
        case CONNECTION_ERROR:
            setTimeout(() => {
                Logger.remoteLog("TV reload by connection error");
                STB.reload();
            }, 30000);
            return {
                ...state,
                connectionError: true,
            };
        case SET_LOCATION_DATA:
            if (action.payload?.roomInfo?.number) {
                storage.set("roomNumber", Number(action.payload.roomInfo.number));
            }
            if (action.payload?.projectRef) {
                storage.set("projectRef", action.payload.projectRef);
            }

            return {
                ...state,
                locationData: action.payload,
            };
        case SET_SESSION_DATA:
            if (action.payload?.messages) {
                unreadMsgs = action.payload.messages.filter((msg) => !msg.isRead).length;
            }
            if (action.payload?.stayguest?.id) {
                storage.set("lastStayGuestId", action.payload.stayguest.id);
            }

            return {
                ...state,
                sessionData: action.payload,
                unreadMessages: unreadMsgs || null,
            };
        case SET_APP_INITIALIZED:
            return {
                ...state,
                initialized: true,
            };
        case SET_FONTS_READY:
            return {
                ...state,
                fontsReady: true,
            };
        case SET_LIBRARIES_READY:
            return {
                ...state,
                librariesReady: true,
            };
        case SET_MESSAGE_READ:
            state.sessionData.messages.forEach((msg) => {
                if (Number(msg.id) === Number(action.payload)) {
                    msg.isRead = true;
                }
            });

            if (state.sessionData?.messages) {
                unreadMsgs = state.sessionData.messages.filter((msg) => !msg.isRead).length;
            }
            return {
                ...state,
                sessionData: state.sessionData,
                unreadMessages: unreadMsgs || null,
            };
        case ADD_FAVOURITE:
            if (!state.locationData.deviceInfo.favouriteChannelIDs) {
                state.locationData.deviceInfo.favouriteChannelIDs = [action.payload];
            } else if (state.locationData.deviceInfo.favouriteChannelIDs.indexOf(Number(action.payload)) === -1) {
                state.locationData.deviceInfo.favouriteChannelIDs.push(action.payload);
            }
            saveFavourites(state.sessionData?.stayguest?.id, state.locationData.deviceInfo.favouriteChannelIDs);

            return {
                ...state,
                locationData: {
                    ...state.locationData,
                    favouriteChannelIDs: [...state.locationData.deviceInfo.favouriteChannelIDs],
                },
            };
        case DELETE_FAVOURITE:
            if (state.locationData.deviceInfo.favouriteChannelIDs?.indexOf(Number(action.payload)) > -1) {
                removeArrayItem(state.locationData.deviceInfo.favouriteChannelIDs, Number(action.payload));
            }
            saveFavourites(state.sessionData?.stayguest?.id, state.locationData.deviceInfo.favouriteChannelIDs);

            return {
                ...state,
                locationData: {
                    ...state.locationData,
                    favouriteChannelIDs: [...state.locationData.deviceInfo.favouriteChannelIDs],
                },
            };
        case SET_ALARM:
            return {
                ...state,
                sessionData: {
                    ...state.sessionData,
                    alarm: action.payload,
                },
            };
        case UPDATE_STATUS:
            return {
                ...state,
                forceStatusUpdate: new Date(),
            };
        case STATUS_UPDATE_VIA_MQTT:
            return {
                ...state,
                forceMQTTUpdate: new Date(),
            };
        case SET_TV_APP_OUTDATED:
            sessionStorage.setItem("tvAppOutdated", true);
            return {
                ...state,
                tvAppOutdated: true,
            };

        case SET_WELCOMED:
            if (inPreview()) {
                state.sessionData.stayguest.stayGuestRooms = [{ roomID: state.sessionData.room.id }];
            }
            if (
                state.sessionData?.stayguest?.stayGuestRooms?.filter(
                    (room) => Number(room.roomID) == Number(state.sessionData.room.id),
                )?.[0]
            ) {
                state.sessionData.stayguest.stayGuestRooms.filter(
                    (room) => Number(room.roomID) == Number(state.sessionData.room.id),
                )[0].welcomed = true;
            }
            return {
                ...state,
                sessionData: {
                    ...state.sessionData,
                },
            };
        case MESSAGES_NOT_SHOWN:
            return {
                ...state,
                messagesNotShown: action.payload,
            };
        case SET_MESSAGES:
            return {
                ...state,
                sessionData: { ...state.sessionData, messages: action.payload },
            };
        case UPDATE_POWER_STATUS:
            return {
                ...state,
                powerStatus: action.payload,
            };
        case CENTER_FULL_SCREEN_MESSAGE:
            const _text = action.payload.text;
            const callback = action.payload.callback;
            if (callback) {
                callback();
            }
            return {
                ...state,
                centerFullScreenMsg: action.payload.text,
            };
        default:
            return state;
    }
}

const saveFavourites = (guestId, favouriteChannelIDsRef) => {
    if (SESSION.isTVOnCheckout()) {
        localStorage.setItem("favOnCheckout", JSON.stringify(favouriteChannelIDsRef));
        return;
    }

    Query({
        query: `
                mutation{ 
                    setFavouriteChannels(guestID: "${guestId}" channelIDs:[${favouriteChannelIDsRef.join(",")}])
                        {
                        error{
                            code
                            msg
                        }
                        ok
                    }
                }
        `,
        onResponse(res) {
            if (res?.data?.setFavouriteChannels?.ok) {
                console.log("Favoritos guardados");
            }
        },
    });
};
