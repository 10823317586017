import { useEffect } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
    connectionError,
    setFontsReady,
    setInstalled,
    setLocationData,
    setServerOnline,
} from "../../../actions/statusActions";
import { showLoading } from "../../../actions/uiActions";
import { Theme } from "../../../utils/theme";
import { Media } from "../../apis/media";
import { STB } from "../../apis/stb";
import Query from "../../query";
import { DEVICE_FUNCTION, DEVICE_FUNCTION_LIST, PERMISSION, ZAFIRO_MODES } from "../../../utils/constants";
import { SESSION } from "../../../utils/session";
import { dispatchErrorConnectionEvent, EVENTS } from "../../../utils/eventsConst";
import { Logger } from "../../../utils/logger";
import { ROOMTV_CONTENTS } from "../../../layouts/commonZones/Contents";
import timemachine from "timemachine";

const IdentifyDevice = () => {
    const dispatch = useDispatch();

    const installed = useSelector((state) => state.status.installed);
    const texts = useSelector((state) => state.ui.texts);

    useEffect(() => {
        //Remove token to avoid wrong identifications
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has("token") && searchParams.has("stayToken")) {
            STB.ref = "inPreview";
            showPreview(searchParams);
            return;
        }
        if (searchParams.has("token") && searchParams.has("screenId") && searchParams.has("designId")) {
            STB.ref = "inSnapShot";
            showSnapShot(searchParams);
            return;
        }
        sessionStorage.removeItem("inPreview");
        sessionStorage.removeItem("inSnapShot");
        sessionStorage.removeItem("screenId");
        sessionStorage.removeItem("designId");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("tvAppOutdated");
        STB.checkOnLine();
        STB.getMac(checkDevice);
        Media.stop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showPreview = (searchParams) => {
        sessionStorage.setItem("inPreview", true);
        sessionStorage.setItem("token", searchParams.get("token"));
        sessionStorage.setItem("stayToken", searchParams.get("stayToken"));
        searchParams.has("lang") && localStorage.setItem("lang", searchParams.get("lang"));
        getLocationData();
    };

    const showSnapShot = (searchParams) => {
        sessionStorage.setItem("inSnapShot", true);
        sessionStorage.setItem("screenId", searchParams.get("screenId"));
        sessionStorage.setItem("designId", searchParams.get("designId"));
        sessionStorage.setItem("token", searchParams.get("token"));
        // sessionStorage.setItem("screenwidth", searchParams.get("screenwidth"));
        // sessionStorage.setItem("screenheight", searchParams.get("screenheight"));
        // window.innerWidth = parseInt(searchParams.get("screenwidth"));
        // window.innerHeight = parseInt(searchParams.get("screenheight"));
        // document.body.style.width = searchParams.get("screenwidth") + "px";
        // document.body.style.height = searchParams.get("screenheight") + "px";
        // window.resizeTo(parseInt(searchParams.get("screenwidth")), parseInt(searchParams.get("screenheight")));
        searchParams.has("lang") && localStorage.setItem("lang", searchParams.get("lang"));
        getLocationData();
    };

    //Check if this device is installed
    const checkDevice = () => {
        if (!installed && STB.ref && !["inPreview", "inSnapShot"].includes(STB.ref)) {
            Query({
                query: `
                    mutation{                        
                        identifyDevice (
                        deviceRef:"${STB.ref}" 
                        ${localStorage.getItem("lang") ? `language:"${localStorage.getItem("lang")}"` : ""}
                        ${
                            localStorage.getItem("firmwareVersion")
                                ? `firmwareVersion:"${localStorage.getItem("firmwareVersion")}"`
                                : ""
                        }
                        ${STB.ip ? `ip:"${STB.ip}"` : ""}
                        )
                        {
                            error 
                                {
                                    code 
                                    msg
                                } 
                            ok 
                            token
                            stayToken
                        }
                    }
              `,
                onResponse(res) {
                    if (res?.data?.identifyDevice?.ok) {
                        STB.renewToken(res.data.identifyDevice.token);
                        sessionStorage.setItem("token", res.data.identifyDevice.token);
                        sessionStorage.setItem("stayToken", res.data.identifyDevice.stayToken);
                        //If device is installed => get location information
                        getLocationData();
                    } else if (res?.data?.identifyDevice?.Error?.Code === 401) {
                        Theme.addThemeStyles(true);
                        dispatch(setServerOnline(true));
                        dispatch(setInstalled(false));
                        dispatch(showLoading(false));
                    } else {
                        throwConnectionError("no data in identifyDevice");
                    }
                },
            });
        } else {
            throwConnectionError("no STB.ref, check to reload");
        }
    };
    const getLocationData = () => {
        Query({
            query: `
            {
                tvSession {
                    isOk
                    error

                    projectInfo {
                        ref
                        name
                        countryRef
                        timezone
                        chainName
                        permissions
                        currency
                        timezone
                        langs {
                            languageRef
                            isDefault
                        }
                        highAvailabilityConfig {
                            alwaysDisplayTvChannels
                            messages {
                            connectionLost
                            connectionRestored
                            }
                        }
                        tags {
                            tag
                            value
                        }
                        castingGatewayIP
                        PMSActive
                        EPGActive
                        VODDomain
                    }

                    guestInfo {
                        id
                        title
                        name
                        surname
                        fullName
                        languageRef
                        checkoutDate
                        country
                    }

                    deviceInfo {
                        type
                        function
                        debugMode
                        favouriteChannelIDs
                        settings {
                            airserverAccountCode
                            rememberLastTvChannel
                            resetCredentialsHour
                            tvInputs {
                                deviceType
                                icon
                                imageRef
                                input
                                name
                                pos
                                tvInputID
                            }
                        }
                    }
                    
                    roomInfo {
                        name
                        code
                        number
                        type
                        roomTvId
                        roomTvName
                        roomTvContents
                        chromecastTvInput
                        chromecastModel
                    }

                    wifiInfo {
                        ssid
                        password
                    }

                    chromecastInfo {
                        ref
                        name
                        function
                        pairingURL
                    }

                    theme {
                        themeLogos {
                            imageRef
                            version
                            type
                        }
                        config {
                            colors {
                            defaultBackground
                            defaultForeground
                            focusBackground
                            focusForeground
                            activeBackground
                            activeForeground
                            focusBorderSize
                            }

                            fontStyles {
                            heading {
                                alignment
                                bold
                                font {
                                externalUrl
                                libraryRef
                                }
                                name
                                italic
                                size
                                underline
                            }
                            paragraph {
                                alignment
                                bold
                                font {
                                externalUrl
                                libraryRef
                                }
                                name
                                italic
                                size
                                underline
                            }
                            }
                        }
                    }     
                    serverTime
                }
            }`,
            onResponse(res) {
                localStorage.setItem("currencyChar", "€"); //TODO: get info from server
                if (res?.data?.tvSession?.isOk) {
                    dispatch(setFontsReady(false));
                    // set device time with server time, sync tasks
                    timemachine.config({
                        dateString: res.data.tvSession.serverTime,
                        tick: true,
                    });
                    if (!res.data.tvSession.projectInfo.currency) {
                        Logger.remoteLog("tvSession - projectCurrency no defined");
                        res.data.tvSession.projectInfo.currency = "EUR"; // default when not set
                    }
                    dispatch(setLocationData(res.data.tvSession));

                    STB.ensurePMSSession(res.data.tvSession?.guestInfo?.id);

                    dispatch(setServerOnline(true));
                    dispatch(setInstalled(true));
                    //apply theme
                    Theme.init(res.data.tvSession);

                    SESSION.init(res.data.tvSession);

                    let hasGridOnHA = SESSION.hasPermission(PERMISSION.HIGH_AVAILABILITY);
                    if (SESSION.isCommonZone()) {
                        hasGridOnHA =
                            SESSION.hasPermission(PERMISSION.HIGH_AVAILABILITY) &&
                            res.data.tvSession?.roomInfo?.roomTvContents.includes(ROOMTV_CONTENTS.TVCHANNELS.id);
                    }

                    // DATA FOR HIGH AVAILABILITY
                    SESSION.saveDataForHA(
                        {
                            [PERMISSION.HIGH_AVAILABILITY]: hasGridOnHA,
                            connectionRdy:
                                res?.data?.tvSession?.projectInfo?.highAvailabilityConfig?.messages?.connectionRestored,
                            continueWatching: texts["Continue watching"],
                        },
                        texts,
                    );

                    SESSION.tvName = `${res?.data?.tvSession?.roomInfo?.name} , ${res?.data?.tvSession?.roomInfo?.roomTvName}`;
                    STB.changeTVName(SESSION.tvName);

                    if (res.data?.tvSession?.deviceInfo?.function == DEVICE_FUNCTION_LIST.TVCAST) {
                        STB.initializeCastServices(
                            res.data?.tvSession?.deviceInfo?.settings?.castingGatewayIP,
                            res.data?.tvSession?.deviceInfo?.settings?.airserverAccountCode,
                        );
                    }

                    //Save VOD domain
                    Media.VODDomain = res.data.tvSession.projectInfo.VODDomain;

                    //Wait fonts to be ready to show app content
                    if (Theme.paragraph?.name) {
                        setTimeout(() => {
                            dispatch(setFontsReady(true));
                        }, 2000);
                        Theme.waitForWebfonts([Theme.paragraph.name], () => {
                            dispatch(setFontsReady(true));
                        });
                    } else {
                        dispatch(setFontsReady(true));
                    }
                } else {
                    Theme.addThemeStyles(true);
                    throwConnectionError("no data in tvSession");
                }
            },
        });
    };

    const throwConnectionError = (logMsg) => {
        console.log("dispatched CONNECTION event");
        console.log(logMsg);
        dispatchErrorConnectionEvent();
    };
};

export default IdentifyDevice;
