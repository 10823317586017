/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useLayoutEffect, useRef, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setMediaEvent } from "../actions/eventsActions";
import { setVideoPos, setBackground, setLandingBackground, displayMenu } from "../actions/uiActions";
import { screenUtils } from "../utils/screenUtils";
import { Theme } from "../utils/theme";
import { createElement, memo } from "preact/compat";
import { Media } from "../hooks/apis/media";
import {
    completeDasURL,
    completeVOD_URL,
    px2vh,
    px2vw,
    createObject,
    getEnvVar,
    isWelcomed,
    focusOnLimbo,
    inHomePage,
    composeNavigationPath,
} from "../utils/utils";
import { ScreenKeyControl } from "../hooks/customKeyControl/screen-keycontrol";
import { STB } from "../hooks/apis/stb";
import {
    NO_RENDERED_WIDGETS,
    EXTRAINFO_WIDGETS,
    MENU_TYPES,
    PATHS,
    TV_MODELS,
    PREVENT_FOCUS_OUTLINE,
} from "../utils/constants";
import { eventHandler } from "../utils/eventHandler";
import focus from "../utils/focus";
import Limbo from "./screen/limbo";
import { EVENTS } from "../utils/eventsConst";

const Screen = ({
    data,
    preventFocus,
    noFocusableWidgets,
    isCCIntructions,
    mediaEvent,
    autoplay,
    videoRepeat,
    propagateKeys,
}) => {
    const dispatch = useDispatch();
    if (!data) {
        return;
    }

    //Store data
    const focusToRestart = useSelector((state) => state.events.focusToRestart);
    const reducerMediaEvent = useSelector((state) => state.events.mediaEvent);
    const sessionData = useSelector((state) => state.status.sessionData);
    const unreadMessages = useSelector((state) => state.status.unreadMessages);
    const alarm = useSelector((state) => state.status.sessionData.alarm);
    const tags = useSelector((state) => state.status.locationData.projectInfo.tags);
    const projectTimezone = useSelector((state) => state.status.locationData.projectInfo.timezone);
    const { menu, popup, landingPage } = useSelector((state) => state.ui);

    const flagGetWidgetPos = useSelector((state) => state.ui.flagGetWidgetPos);
    const activeMenuBtnId = useSelector((state) => state.events.activeMenuBtnId);

    let popupRef = useRef();
    popupRef.current = popup;

    //States
    const [fontsReady, setFontsReady] = useState(false);
    const [bgReady, setBgReady] = useState(false);
    const [videoPresent, setVideoPresent] = useState(false);
    const [forceRerender, setForceRerender] = useState();

    let videoPresentRef = useRef();
    videoPresentRef.current = videoPresent;

    const isLanding = landingPage ? data.id === landingPage.id : false;

    //Listeners
    useEffect(() => {
        return () => {
            window.ScreenRender.prevDataId = null;
            window.ScreenRender.exit();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        // when layout is rendered
        screenUtils.getoffSetNavigableWidgets();
        return () => {
            Media.exitLayout();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        // when layout is rendered
        screenUtils.getoffSetNavigableWidgets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bgReady]);

    useEffect(() => {
        setVideoPresent(null);
        videoPresentRef.current = null;
        setTimeout(function () {
            onWidgetLoaded();
        }, 150);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (
            !STB.supportVideoLoop &&
            (mediaEvent?.code === EVENTS.MEDIA.STATUS_END_OF_STREAM ||
                reducerMediaEvent?.code === EVENTS.MEDIA.STATUS_END_OF_STREAM) &&
            videoPresent &&
            videoPresent.repeat
        ) {
            Media.stop();
            playVideo(true);
            //This force the widget update
            dispatch(setMediaEvent(null));
        } else if (
            mediaEvent?.code === EVENTS.MEDIA.STATUS_END_OF_STREAM ||
            reducerMediaEvent?.code === EVENTS.MEDIA.STATUS_END_OF_STREAM
        ) {
            Media.stop();
            setForceRerender(new Date().getTime());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaEvent, reducerMediaEvent]);

    /////////////////////////
    //Functions
    /////////////////////////
    //Wait screen resources (fonts, background) to be ready to show screen content
    const ensureScreenResources = () => {
        const fontsToInclude = screenUtils.includeFonts();
        if (fontsToInclude.length > 0) {
            setFontsReady(false);
            setTimeout(() => {
                setFontsReady(true);
            }, 500);
            Theme.waitForWebfonts(fontsToInclude, () => {
                setFontsReady(true);
            });
        } else {
            setFontsReady(true);
        }

        if (screenUtils?.contentStyle?.images?.[0]) {
            let imageObj = new Image();

            imageObj.onload = function () {
                setBgReady(true);
            };
            imageObj.onerror = function () {
                setBgReady(true);
            };
            imageObj.src =
                screenUtils.contentStyle.images[0].externalUrl ||
                completeDasURL(screenUtils.contentStyle.images[0].libraryRef, { width: "full" });
        } else {
            setBgReady(true);
        }
    };
    //Print widget
    const printWidget = (widgetItems, widget, container) => {
        if (widget && NO_RENDERED_WIDGETS.indexOf(widget.type) > -1) {
            return;
        }
        let action = getWidgetAction(widget);
        const widgetData = JSON.parse(widget.data);
        const extraInfo =
            EXTRAINFO_WIDGETS.indexOf(widget.type) > -1
                ? {
                      unreadMessages,
                      alarm: {
                          configured: !!alarm,
                          date: alarm ? new Date(alarm.startTime) : null,
                      },
                      timezone: projectTimezone,
                      statusStyle: focus.value.current?.indexOf(widget.ID) > -1 ? "focusColor" : "defaultColor",
                      focusedBtnId: focus.value.current,
                      playingVideo: Media.playingMedia,
                      autoplay,
                      videoRepeat,
                  }
                : null;
        let widgetRendered = window.ScreenRender.widget(widget, container, extraInfo);
        widgetRendered.setAttribute("widgetType", widget.type);
        widgetRendered.tabIndex = 0;

        const isVideo = widget.type === "VIDEO";
        const isYoutubeVideo = isVideo && widgetData?.video?.[0]?.externalUrl?.includes("youtube.com");
        const isExternalVideo = isVideo && widgetData?.video?.[0]?.externalUrl;
        const preventRefocus = PREVENT_FOCUS_OUTLINE.includes(widget.type) || isExternalVideo;
        let classes = [
            widgetRendered.style.border ? "btn-focusBorder" : "btn-focusOutline",
            (isNavigable(widget) && action) || (isVideo && !isCCIntructions) ? "navigable" : null,
            focus.value.current?.indexOf(widget.ID) > -1 && !preventRefocus ? "focusOutline" : null,
        ];
        //Add TV-app widget classes and styles to the rendered widget
        widgetRendered.className = classes.join(" ");
        //Get rendered widget
        const widgetDiv = createElement("div", {
            onClick: () => {
                eventHandler.dispatchNavigation({ dispatch, action });
                if (isVideo) {
                    if (isYoutubeVideo) {
                        const videoElem = document.getElementById(`video_${widget.ID}`);
                        if (videoElem) {
                            //video está parado
                            if (videoElem.src.indexOf("autoplay=0") > -1) {
                                videoElem.src = videoElem.src.replace("autoplay=0", "autoplay=1");
                                STB.needCanvas &&
                                    dispatch(
                                        setVideoPos({
                                            x: videoPresentRef.current.x,
                                            y: videoPresentRef.current.y,
                                            width: videoPresentRef.current.width,
                                            height: videoPresentRef.current.height,
                                        }),
                                    );
                            } else {
                                videoElem.src = videoElem.src.replace("autoplay=1", "autoplay=0");
                            }
                        }
                        return;
                    }
                    if (Media.playingMedia) {
                        Media.stop();
                    } else {
                        playVideo(true);
                    }
                    setForceRerender(new Date().getTime());
                }
            },
            dangerouslySetInnerHTML: {
                __html: widgetRendered.outerHTML,
            },
        });
        //Set video data to play on widget load
        if (widget.type === "VIDEO" && !videoPresent && widgetData?.video) {
            const isLibraryVideo = widgetData?.video?.[0]?.libraryRef;
            setVideoPresent({
                x: px2vw(widgetRendered.style.left.replace("px", "")),
                y: px2vh(widgetRendered.style.top.replace("px", "")),
                width: px2vw(widgetRendered.style.width.replace("px", "")),
                height: px2vh(widgetRendered.style.height.replace("px", "")),
                url:
                    (isLibraryVideo && completeVOD_URL(widgetData.video[0].libraryRef)) ||
                    widgetData?.video?.[0].externalUrl,
                repeat: videoRepeat || widgetData.repeat,
                autoplay: autoplay || widgetData.autoplay || isCCIntructions,
                isLibraryVideo,
                isYoutubeVideo,
                id: widget.ID,
            });
        }
        if (typeof widgetRendered === "object") {
            widgetItems.push(widgetDiv);
        }
    };

    const playVideo = (startPlayback) => {
        if (
            videoPresentRef.current?.isLibraryVideo &&
            (!Media.playingMedia || startPlayback) &&
            videoPresentRef.current &&
            (videoPresentRef.current.autoplay || startPlayback)
        ) {
            Media.playHLSVideo(videoPresentRef.current.url, videoPresentRef.current.repeat);
            setTimeout(() => {
                dispatch(
                    setVideoPos({
                        x: videoPresentRef.current.x,
                        y: videoPresentRef.current.y,
                        width:
                            videoPresentRef.current?.width === 100 && STB.model === TV_MODELS.GOOGLE_TV
                                ? 99.99
                                : videoPresentRef.current.width,
                        height: videoPresentRef.current.height,
                    }),
                );
            }, 100);
        } else if (videoPresentRef.current && !videoPresentRef.current?.isLibraryVideo) {
            (videoPresentRef.current?.autoplay || startPlayback) &&
                !videoPresentRef.current?.isYoutubeVideo &&
                setTimeout(() => {
                    Media.playHTMLVideo(`video_${videoPresentRef.current?.id}`);
                }, 1000);
            STB.needCanvas &&
                dispatch(
                    setVideoPos({
                        x: videoPresentRef.current.x,
                        y: videoPresentRef.current.y,
                        width: videoPresentRef.current.width,
                        height: videoPresentRef.current.height,
                    }),
                );
        }
    };

    const unstackOnLoad = () => {
        if (sessionStorage.getItem("unstackOnLoad")) {
            sessionStorage.removeItem("unstackOnLoad");
            focus.value.unstack();
        }
    };

    const onWidgetLoaded = () => {
        sessionData.noFocusableWidgets = noFocusableWidgets;
        let widgetToFocus = window.ScreenRender?.getFirstWidgetToFocus(sessionData);
        Media.stop();
        // when loading screen from Welcome or CCinstructions focus could be in limbo no need to refocus noFocus(general limbo)
        //ensure to play video if there is no focus on the screen
        if (
            preventFocus ||
            !screenUtils.contentWidgets ||
            (screenUtils.contentWidgets && screenUtils.contentWidgets.length === 0)
        ) {
            if (
                !widgetToFocus &&
                isWelcomed(sessionData) &&
                (menu?.type !== MENU_TYPES.FIXED || menu?.temporaryType === MENU_TYPES.HIDDEN)
            ) {
                unstackOnLoad();
                focus.value.replace("limbo");
            }
            const menuWidgetToFocus = window.MenuRender?.getFirstWidgetToFocus(sessionData, true);
            if (
                !menuWidgetToFocus &&
                inHomePage() &&
                menu?.type === MENU_TYPES.FIXED &&
                menu?.temporaryType !== MENU_TYPES.HIDDEN &&
                widgetToFocus
            ) {
                // no widget to focus in menu fixed => inside landing set focus to first widget of screen
                focus.value.replace(widgetToFocus);
            }
            playVideo();
            return;
        }
        if (!preventFocus && widgetToFocus) {
            if (sessionStorage.getItem("unstackOnLoad")) {
                sessionStorage.removeItem("unstackOnLoad");
                focus.value.unstack();
            } else if (!popup) {
                const existItemTofocus = document.getElementById(widgetToFocus);
                if (existItemTofocus) {
                    focus.value.replace(widgetToFocus);
                } else {
                    setTimeout(function () {
                        focus.value.replace(widgetToFocus);
                    }, 250);
                }
            }
        } else if (!widgetToFocus && menu.type === MENU_TYPES.FIXED && menu.temporaryType !== MENU_TYPES.HIDDEN) {
            if (activeMenuBtnId) {
                focus.value.replace(activeMenuBtnId);
            } else {
                let menuWidgetToFocus = window.MenuRender.getFirstWidgetToFocus(sessionData);
                focus.value.replace(menuWidgetToFocus);
            }
            unstackOnLoad();
        } else if (!preventFocus || !widgetToFocus) {
            unstackOnLoad();
            focus.value.replace("limbo");
        }
        // first time load => hide menu
        if (menu && menu.type === MENU_TYPES.HIDDEN && !focusToRestart) {
            setTimeout(() => {
                dispatch(displayMenu(false));
            }, 900);
        }

        playVideo();
    };

    const isNavigable = (widget) => {
        return Array("LANGSELECTION", "SLIDER").indexOf(widget.type) === -1;
    };

    const getWidgetAction = (widget) => {
        let action = {};

        //Special widgets
        if (widget.type === "MESSAGES") {
            action.layout = "messages";
            return action;
        }
        if (widget.type === "ALARM") {
            action.layout = "alarm";
            return action;
        }
        if (!widget.actions) {
            return null;
        }

        let actions = JSON.parse(widget.actions);
        if (widget.type === "SLIDER") {
            const widgetItemsData = JSON.parse(widget.data).items;
            let sliderItemSelected = 0;
            if (
                window?.ScreenRender?.sliders &&
                window?.ScreenRender?.sliders.find((slider) => slider.id === widget.ID)
            ) {
                sliderItemSelected = window?.ScreenRender?.sliders.find((slider) => slider.id === widget.ID).item;
            }
            const widgetItemsVisible = widgetItemsData?.filter((item) => item.visibility);
            actions = { actions: widgetItemsVisible?.[sliderItemSelected]?.actions };
        }

        if (!actions || typeof actions.actions === "undefined") {
            return null;
        }
        actions = actions.actions;
        if (
            actions.length === 0 ||
            !actions[0] ||
            !actions[0].type ||
            typeof actions[0].value === "undefined" ||
            !actions[0].value
        ) {
            // no full action [need type + value]
            return null;
        }
        const EVAL_ACTION = {
            section: () => {
                if (actions[0].value === "landing") {
                    action.layout = "HOME";
                } else if (actions[0].value.indexOf(":") > -1) {
                    const layoutAndId = actions[0].value.split(":");
                    action.layout = layoutAndId[0];
                    action.id = layoutAndId[1];
                } else {
                    action.layout = actions[0].value;
                }
            },

            content: () => {
                action.layout = "screen";
                action.id = actions[0].value;
            },
            default: () => {
                action.layout = actions[0].type;
                action.id = actions[0].value;
            },
        };
        EVAL_ACTION[actions[0].type] ? EVAL_ACTION[actions[0].type]() : EVAL_ACTION.default();
        return action;
    };

    //////////////////////////
    //Add Screen key control
    if (!window.ScreenRender) {
        // eslint-disable-next-line no-undef
        window.ScreenRender = createObject(Render);
    }
    //Initialize Render if data change
    if (data && data.id !== window.ScreenRender.prevDataId) {
        window.ScreenRender.prevDataId = data.id;
        screenUtils.init(data, menu && menu.type === MENU_TYPES.FIXED ? menu.pos : null, isCCIntructions);
        screenUtils.isLanding = isLanding;
        window.ScreenRender.init(localStorage.getItem("lang"), data, Theme.config, tags, Theme.logos);
        window.ScreenRender.dasURL = `${getEnvVar("API")}das/`;
        window.ScreenRender.castURL = tags?.find((tag) => tag.tag === "pairingurl")?.value;
        //Wait fonts to be ready to show screen content
        ensureScreenResources();
        //Background control
        if (isLanding || screenUtils.contentStyle?.useHomeBackground) {
            dispatch(setLandingBackground());
        } else if (screenUtils.contentStyle && !screenUtils.contentStyle.useHomeBackground) {
            dispatch(
                setBackground({
                    image: screenUtils.getBgURL(screenUtils.contentStyle) || null,
                    bgColor: screenUtils.contentStyle.bgColor || "",
                    opacity: screenUtils.contentStyle.opacity ? screenUtils.contentStyle.opacity / 100 : 1,
                }),
            );
        }
    }

    let widgetItems = [];

    /*Add widgets */
    screenUtils.contentWidgets.forEach((widget) =>
        screenUtils.hasTVPos(widget) ? printWidget(widgetItems, widget, null) : null,
    );
    /*Add container widgets */
    screenUtils.contentWidgets.forEach((widget) =>
        widget.type === "CONTAINER"
            ? widget.contentWidgets?.map((cWidget) =>
                  screenUtils.hasContainerTVPos(cWidget, widget) ? printWidget(widgetItems, cWidget, widget) : null,
              )
            : null,
    );

    const widgetContainer = createElement("div", {}, widgetItems);

    const onkeydown = (e) => {
        if (propagateKeys) {
            return;
        }
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;

        if (keyData?.type === "arrow" && !focusOnLimbo()) {
            ScreenKeyControl({
                direction: keyData.value,
                dispatch,
                sessionData,
                menu,
                flagGetWidgetPos,
                activeMenuBtnId,
            });
            preventDefault = true;
        }
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return fontsReady && bgReady ? (
        <>
            <div
                id="screenContent"
                className="h-full absolute w-full top-0 bg-cover bg-center bg-no-repeat"
                style={screenUtils.getContentStyle()}
                onKeyDown={(e) => {
                    onkeydown(e);
                }}
            >
                <Limbo />
                {widgetContainer}
            </div>

            {screenUtils.getContentAudioURL() ? (
                <audio id="bgMusic" autoPlay loop src={screenUtils.getContentAudioURL()} style="display:none" />
            ) : null}
        </>
    ) : null;
};

export default memo(Screen);
