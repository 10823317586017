/* eslint-disable radix */
import { useEffect, useRef, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import Screen from "./screen";
import {
    displayMenu,
    setBackground,
    setLandingBackground,
    setMenuTemporaryType,
    setPopUp,
    setVideoPos,
} from "../actions/uiActions";
import { CSSTransition } from "react-transition-group";
import CcInstructions from "./ccInstructions";
import {
    SCREEN,
    MENU_TYPES,
    ANIMATION_TIMEOUT,
    PATHS,
    PATHS_WITH_NO_MENU,
    MESSAGES_STYLES,
    LOADING_STYLE,
} from "../utils/constants";
import LibraryContent from "./libraryContent";
import { Route, Switch, useLocation } from "wouter/preact";
import focus from "../utils/focus";
import getScreen from "../hooks/GraphqlCalls/getScreen";
import getCCInstructions from "../hooks/GraphqlCalls/getCCInstructions";
import { carrito } from "./sales/signals/carrito";
import { Redirect } from "wouter";
import NoLanding from "../layouts/noLanding";
import Loading from "./loading";
import { inHomePage, inSnapShot, printCenteredMessage } from "../utils/utils";
import storage from "../utils/storage";
import ReactHtmlParser from "react-html-parser";
import { eventHandler } from "../utils/eventHandler";
import Checkoutsuccess from "../layouts/checkoutsuccess";
import Tv from "../layouts/tv/tv";
import ConnectionError from "./connectionError";
import NoContent from "./NoContent";
import { Movies, HollywoodMovies, AdultMovies, MovieDetail } from "../layouts/movies/";
import { SESSION } from "../utils/session";
import {
    CartComp,
    CategoriesComp,
    MyOrdersComp,
    MyOrdersDetailComp,
    ProductComp,
    RoomshopComp,
    SubcategoriesComp,
    SubcategoryComp,
} from "./sales/layouts/";
import { STB } from "../hooks/apis/stb";
import { navigate } from "wouter/use-location";
const Content = () => {
    const dispatch = useDispatch();
    const [location] = useLocation();
    //Store
    const landingPage = useSelector((state) => state.ui.landingPage);
    const menu = useSelector((state) => state.ui.menu);
    const texts = useSelector((state) => state.ui.texts);
    //States
    const [screenData, setScreenData] = useState();
    const [error, setError] = useState();
    const [loadingContent, showLoadingContent] = useState();
    const tags = useSelector((state) => state.status.locationData.projectInfo.tags);
    const projectLanguageCode = SESSION.projectDefaultLang;

    const preventFocusOnHome = useRef(false);
    let loadingTimeout = useRef();
    const MAX_LOADING_TIME = 20000;

    //Listeners
    //if not current Layout request landing page

    const hideMenuInLayout = () => {
        if (menu.temporaryType !== MENU_TYPES.HIDDEN) {
            dispatch(setMenuTemporaryType(MENU_TYPES.HIDDEN));
            dispatch(displayMenu(false));
        }
    };
    const showMenuInLayout = () => {
        dispatch(setMenuTemporaryType(null));
        if (menu?.type === MENU_TYPES.FIXED) {
            dispatch(displayMenu(true));
        }
    };
    // SET screenData + menuTemporaryType by location
    useEffect(() => {
        eventHandler.locationStack(location);
        setError(null);
        if (location?.indexOf("/roomshop") > -1 || location?.indexOf("/screen") > -1) {
            if (location?.indexOf("/roomshop") > -1) {
                if (
                    location.indexOf("categories/") > -1 &&
                    eventHandler.previousPath?.indexOf("categories/") > -1 /* ||
                    eventHandler.previousPath?.indexOf("/cart") > -1 */
                ) {
                    //DO Nothing
                } else if (sessionStorage.getItem("unstackOnLoad")) {
                    sessionStorage.removeItem("unstackOnLoad");
                    focus.value.unstack();
                } else if (eventHandler.previousPath?.indexOf("/cart") === -1) {
                    focus.value.stack();
                }
            }
            showLoadingContent(true);
        }
        preventFocusOnHome.current =
            (!eventHandler.previousPath || eventHandler.previousPath === PATHS.TIZEN_ROOT) &&
            menu?.type === MENU_TYPES.FIXED &&
            inHomePage();

        setScreenData(null);
        const hideMenuByPath =
            location.split("/")?.[1] && location.split("/")[1] !== ""
                ? PATHS_WITH_NO_MENU.pathIncludes("/" + location.split("/")[1])
                : null;

        if (hideMenuByPath) {
            hideMenuInLayout();
        }
        if (location === PATHS.ROOT || location === PATHS.TIZEN_ROOT) {
            //This timeout ensure that previous content is removed. Otherwise I get text from previous screen
            setScreenData({ ...landingPage, renderType: SCREEN });
            showMenuInLayout();
        } else if (location === PATHS.CCINSTRUCTIONS) {
            showLoadingContent(true);
            getCCInstructions((data) => {
                setScreenData(data);
            });
        } else if (location.indexOf(PATHS.SCREENS) > -1) {
            const screenID = location.replace(PATHS.SCREENS, "");
            getScreen(screenID, landingPage.designID, (data) => {
                if (!data) {
                    showLoadingContent(false);
                    setError(
                        "<div>There is no content for this screen</div><div class='text-xl mt-4'>Press BACK or HOME  to continue</div>",
                    );
                    setTimeout(function () {
                        STB.reload();
                    }, 2000);
                    return;
                }
                setScreenData(data);
                const contentStyle = JSON.parse(data.contentStyle);
                if (menu && menu.type === MENU_TYPES.FIXED && !contentStyle.showHomeMenu) {
                    hideMenuInLayout();
                } else {
                    showMenuInLayout();
                }
            });
        } else if (
            (location?.indexOf("roomshops") > -1 || location?.indexOf("myorders") > -1) &&
            sessionStorage.getItem("salesBg")
        ) {
            const salesBg = JSON.parse(sessionStorage.getItem("salesBg"));
            dispatch(setBackground(salesBg));
        } else {
            dispatch(setLandingBackground());
        }
        eventHandler.setPreviousPath(location);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        clearTimeout(loadingTimeout.current);
        if (loadingContent) {
            loadingTimeout.current = setTimeout(() => {
                showLoadingContent(false);
                setError(
                    "<div>There is no content for this screen</div><div class='text-xl mt-4'>Press BACK or HOME  to continue</div>",
                );
                focus.value.replace("noFocus");
            }, MAX_LOADING_TIME);
        }
    }, [loadingContent]);

    useEffect(() => {
        screenData && showLoadingContent(false);
    }, [screenData]);

    const onLayoutExit = () => {
        dispatch(setVideoPos(null));
    };

    const allShopsTokens = sessionStorage.getItem("allShopsTokens")
        ? JSON.parse(sessionStorage.getItem("allShopsTokens")).join(",")
        : "";

    if (inSnapShot() && location?.indexOf("/screen/") === -1) {
        console.log("REDIRIGIENDO POR SANPSHOT");
        navigate(`/screen/${sessionStorage.getItem("screenId")}`);
    }

    return (
        <>
            {error ? (
                <div style={{ fontFamily: "Lato", fontWeight: "bold" }}>
                    {printCenteredMessage(ReactHtmlParser(error), MESSAGES_STYLES.OVERLAY)}
                </div>
            ) : null}
            {loadingContent ? (
                <div className="fixed w-full h-full z-100">
                    <Loading style={LOADING_STYLE} />
                </div>
            ) : null}
            <ConnectionError />
            <Switch>
                <Route path={PATHS.NOLANDING}>
                    <NoLanding />
                </Route>
                <Route path={PATHS.TIZEN_ROOT}>{(params) => <Redirect to={`/`} replace={true}></Redirect>}</Route>
                <Route path={PATHS.ROOT}>
                    <>
                        <CSSTransition
                            in={!!screenData}
                            timeout={ANIMATION_TIMEOUT}
                            classNames="fadeInAnimation" // fadeInAnimation , topAnimation, leftAnimation
                            unmountOnExit
                            onExit={onLayoutExit}
                        >
                            <Screen data={screenData} preventFocus={preventFocusOnHome.current} />
                        </CSSTransition>
                    </>
                </Route>
                <Route path={PATHS.ROOMSHOPS}>
                    {() => (
                        <RoomshopComp
                            config={{
                                showLoadingContent,
                                landingPage,
                                texts,
                                projectLanguageCode,
                                allShopsTokens,
                                tags,
                            }}
                        />
                    )}
                </Route>
                <Route path={PATHS.CATEGORIES}>
                    {(params) => (
                        <CategoriesComp
                            params={params}
                            config={{
                                showLoadingContent,
                                landingPage,
                                texts,
                                projectLanguageCode,
                                allShopsTokens,
                            }}
                        />
                    )}
                </Route>
                <Route path={PATHS.SUBCATEGORIES}>
                    {(params) => (
                        <SubcategoriesComp
                            params={params}
                            config={{
                                showLoadingContent,
                                landingPage,
                                texts,
                                projectLanguageCode,
                                allShopsTokens,
                            }}
                        />
                    )}
                </Route>
                <Route path={PATHS.SUBCATEGORY}>
                    {(params) => (
                        <SubcategoryComp
                            params={params}
                            config={{
                                showLoadingContent,
                                landingPage,
                                texts,
                                projectLanguageCode,
                                allShopsTokens,
                            }}
                        />
                    )}
                </Route>
                <Route path={PATHS.PRODUCT}>
                    {(params) => {
                        let inEdition = null;
                        //recuperar datos a editar
                        if (params.timestamp) {
                            inEdition = carrito.value[storage.get("orderToken")].find(
                                (item) =>
                                    item.variant?.toString() == params.variantId && item.timestamp === params.timestamp,
                            );
                        }
                        return (
                            <ProductComp
                                params={params}
                                config={{
                                    showLoadingContent,
                                    landingPage,
                                    texts,
                                    projectLanguageCode,
                                    allShopsTokens,
                                }}
                                inEdition={inEdition}
                            />
                        );
                    }}
                </Route>
                <Route path={PATHS.CART}>
                    {(params) => (
                        <CartComp
                            params={params}
                            config={{ showLoadingContent, landingPage, texts, projectLanguageCode, allShopsTokens }}
                        />
                    )}
                </Route>
                <Route path={PATHS.MYORDERS}>
                    {(params) => (
                        <MyOrdersComp
                            config={{ showLoadingContent, landingPage, texts, projectLanguageCode, allShopsTokens }}
                        />
                    )}
                </Route>
                <Route path={PATHS.MYORDERDETAIL}>
                    {(params) => (
                        <MyOrdersDetailComp
                            params={params}
                            config={{ showLoadingContent, landingPage, texts, projectLanguageCode, allShopsTokens }}
                        />
                    )}
                </Route>
                <Route path={PATHS.TV}>{() => <Tv />}</Route>
                <Route path={PATHS.CHECKOUTSUCCESS}>
                    {() => (
                        <div class={"section"}>
                            <Checkoutsuccess />
                        </div>
                    )}
                </Route>
                <Route path={PATHS.CCINSTRUCTIONS}>
                    {() => (
                        <CSSTransition
                            in={!!screenData}
                            timeout={ANIMATION_TIMEOUT}
                            classNames="fadeInAnimation" // fadeInAnimation , topAnimation, leftAnimation
                            unmountOnExit
                            onExit={onLayoutExit}
                        >
                            <CcInstructions ccInstructionsData={screenData} />
                        </CSSTransition>
                    )}
                </Route>
                <Route path={`${PATHS.LIBRARY}/:ref`}>
                    {(path) => (
                        <div class={"section"}>
                            <LibraryContent asset={path.ref} />
                        </div>
                    )}
                </Route>
                <Route path={PATHS.SCREEN}>
                    {() => (
                        <>
                            <CSSTransition
                                in={!!screenData}
                                timeout={ANIMATION_TIMEOUT}
                                classNames="fadeInAnimation" // fadeInAnimation , topAnimation, leftAnimation
                                unmountOnExit
                                onExit={onLayoutExit}
                            >
                                <Screen data={screenData} preventFocus={preventFocusOnHome.current} />
                            </CSSTransition>
                        </>
                    )}
                </Route>
                <Route path={PATHS.MOVIES}>{() => <Movies />}</Route>
                <Route path={PATHS.HOLLYWOOD_MOVIES}>{() => <HollywoodMovies />}</Route>
                <Route path={PATHS.ADULT_MOVIES}>{() => <AdultMovies />}</Route>
                <Route>{(params) => <Redirect to={`/`} replace={true}></Redirect>}</Route>
            </Switch>
        </>
    );
};

export default Content;
