import React from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../components/sales/api";
import { screenUtils } from "../../utils/screenUtils";
import { useRef } from "preact/hooks";
import { setMessages } from "../../actions/statusActions";
import { Theme } from "../../utils/theme";
import { getVendureTranslation, printPrice } from "../../utils/utils";

const EURO_CODE = "EUR";
const EURO = "€";
const DOLLAR = "$";
const WHITE_SPACE = `<p style="margin-bottom:1rem">`;

const ParseSalesMessagesTags = (rdyCallBack, parsingState) => {
    const dispatch = useDispatch();
    const messages = useSelector((state) => state.status.sessionData.messages);
    const tags = useSelector((state) => state.status.locationData.projectInfo.tags);
    const texts = useSelector((state) => state.ui.texts);
    const replaceMsgTo = useRef(0);
    const msgReplaced = useRef(0);
    const _ordersIDs = useRef([]);
    const _ordersChannelsIDs = useRef([]);
    const [parsingMsg, setParsingMsg] = parsingState;

    if (parsingMsg) {
        return;
    }
    setParsingMsg(true);

    messages.forEach((msg) => {
        if (
            (!msg?.message?.information?.parsed || msg?.message?.information?.connectionError) &&
            msg?.message?.information?.channelToken &&
            msg?.message?.information?.orderID
        ) {
            if (!_ordersChannelsIDs.current.includes(msg.message.information.channelToken)) {
                _ordersChannelsIDs.current.push(msg.message.information.channelToken);
            }
            if (!_ordersIDs.current.includes(msg.message.information.orderID)) {
                _ordersIDs.current.push(msg.message.information.orderID);
                replaceMsgTo.current++;
            }
        }
    });

    if (replaceMsgTo.current === 0) {
        rdyCallBack(true);
        return;
    }

    //QUERY ALL ORDERS AT ONCE
    Promise.all([
        api.shops(),
        api.orderById(_ordersChannelsIDs.current.join(","), _ordersIDs.current),
        api.orderInfo(_ordersChannelsIDs.current.join(","), _ordersIDs.current),
    ]).then(([shops, ordersById, ordersInfo]) => {
        // PARSE EACH MESSAGE
        messages.forEach((msg) => {
            if (
                (!msg?.message?.information?.parsed || msg?.message?.information?.connectionError) &&
                msg?.message?.information?.channelToken &&
                msg?.message?.information?.orderID
            ) {
                if (ordersById.errors || ordersInfo.errors) {
                    msg.message.innerHTML = texts["vendure-error-connection"];
                    msg.message.body = texts["vendure-error-connection"];
                    msg.message.information.connectionError = true;
                    msgReplaced.current++;
                } else {
                    if (msg?.message?.information?.connectionError) {
                        msg.message.information.connectionError = false;
                    }
                    const shopData = shops.find((_shop) => _shop.token === msg.message.information.channelToken);
                    const orderData = ordersById.data.orders.items.find(
                        (order) => parseInt(order.id) === parseInt(msg.message.information.orderID),
                    );
                    const orderExtrasData = ordersInfo.data.orderInfo.find(
                        (order) => parseInt(order.id) === parseInt(msg.message.information.orderID),
                    );

                    if (!orderData || !orderExtrasData) {
                        msg.message.innerHTML = texts["vendure-error-connection"];
                        msg.message.body = texts["vendure-error-connection"];
                        msg.message.information.connectionError = true;
                        msgReplaced.current++;
                    } else {
                        const themeColor = Theme.light
                            ? Theme.getDefaultStyles().gray[800]
                            : Theme.getDefaultStyles().gray[100];
                        const pricesIncludeTax = shopData.pricesIncludeTax ? "WithTax" : "";
                        // ## DELIVERY ## //
                        let _orderSummaryTag = `<div style="margin-left:1rem; margin-right:1rem"><div style="font-weight: 700;border-bottom: solid 1px ${themeColor};">${texts["delivery"]}</div>`;
                        _orderSummaryTag += `<div style="margin-top:1rem; position: relative">`;
                        if (orderData?.shippingLines[0]?.shippingMethod?.code === "pickup") {
                            _orderSummaryTag += `<div>${texts["Pick-up"]} (${orderExtrasData?.customFields?.pickUp?.location})</div>`;
                        } else {
                            _orderSummaryTag += `<div>${texts["pick-up-reception-dec"].replace(
                                "{{place}}",
                                orderExtrasData?.customFields?.pickUp?.location,
                            )}</div>`;
                        }
                        _orderSummaryTag += deliveryHourAndDate(orderData, texts);
                        _orderSummaryTag += `</div>`;
                        _orderSummaryTag += WHITE_SPACE;
                        // ## ORDER ## //
                        _orderSummaryTag += `<div style="font-weight: 700;margin-top: 1rem; padding-top: 0.5rem; border-bottom: solid 1px ${themeColor};">${texts["order"]}</div>`;
                        if (orderExtrasData?.customFields?.items.length > 0) {
                            orderExtrasData.customFields.items.forEach((item) => {
                                const leftMarginNamesAndExtras = 2;
                                _orderSummaryTag += `<div style="margin-top:1rem; position: relative">`;
                                _orderSummaryTag += `<div>${
                                    orderData.state !== "Cancelled"
                                        ? `<span>${item.quantity}x</span>`
                                        : "<span>&nbsp;</span>"
                                }<span style="left: ${leftMarginNamesAndExtras}rem; position: absolute">${
                                    item.name
                                }</span></div>`;
                                if (item.extras.length > 0) {
                                    _orderSummaryTag += `<div style="margin-left: ${leftMarginNamesAndExtras}rem;text-transform: capitalize;font-weight: 600;">${texts["extras"]}:</div>`;
                                    item.extras.forEach((extra) => {
                                        if (!extra) {
                                            return;
                                        }
                                        if (extra.options) {
                                            _orderSummaryTag += `<div style="margin-left: ${leftMarginNamesAndExtras}rem;text-transform: capitalize">${extra.name}</div>`;
                                            extra.options.forEach((element) => {
                                                _orderSummaryTag += `<div style="margin-left: ${
                                                    leftMarginNamesAndExtras * 2
                                                }rem;">${
                                                    orderData.state !== "Cancelled"
                                                        ? `${element.quantity}x ${element.name}`
                                                        : `${element.name}`
                                                }</div>`;
                                            });
                                        } else {
                                            _orderSummaryTag += `<div style="margin-left: ${leftMarginNamesAndExtras}rem;">${extra.quantity}x ${extra.name}</div>`;
                                        }
                                    });
                                }
                                _orderSummaryTag += "</div>";
                            });
                        }
                        _orderSummaryTag += `<div style="margin-top: 1rem; padding-top: 0.5rem; border-top: solid 1px ${themeColor}; "></div>`;
                        if (orderData.state !== "Cancelled") {
                            // PRICE RESUME SUBTOTAL, DELIVERY AND TOTAL only if is not Cancelled
                            if (orderData?.shipping > 0) {
                                // SUBTOTAL
                                _orderSummaryTag += `<div style="display:flex; width: 40%">${
                                    texts["subtotal"]
                                }: <span style="margin-left:auto">${printPrice(
                                    orderData[`subTotal${pricesIncludeTax}`] / 100,
                                    orderData?.currencyCode,
                                )}</span></div>`;
                                // DELIVERY FEE
                                _orderSummaryTag += `<div style="display:flex; width: 40%">${
                                    texts["delivery-fee"]
                                }: <span style="margin-left:auto">${printPrice(
                                    orderData[`shipping${pricesIncludeTax}`] / 100,
                                    orderData?.currencyCode,
                                )}</span></div>`;
                            }
                            // TOTAL
                            _orderSummaryTag += `<div style="display:flex; width: 40%;font-weight: bold; text-transform: capitalize; ">${
                                texts["total"]
                            }: <span style="margin-left:auto">${printPrice(
                                orderData[`total${pricesIncludeTax}`] / 100,
                                orderData?.currencyCode,
                            )}</span></div>`;
                            _orderSummaryTag += `<div class="text-base font-400">${
                                texts[shopData.pricesIncludeTax ? "Tax included" : "Tax excluded"]
                            }</div>`;
                        }

                        // ## COMMENTS ## //
                        if (orderData.modifications.length > 0) {
                            _orderSummaryTag += `<div style="font-weight: 700;margin-top: 1rem; padding-top: 0.5rem; border-bottom: solid 1px ${themeColor};">${texts["comments"]}</div></div>`;
                            _orderSummaryTag += `<div style="margin-top:0.5rem">${orderData.modifications[0].note}</div>`;
                        }

                        // REPLACE SPECIAL TAGS
                        let _msgbody = msg.message.body;
                        _msgbody = _msgbody.replaceAll("\n", "<br>");
                        _msgbody = screenUtils.replaceSpecialTags(_msgbody, tags);
                        // REPLACE SALES TAGS
                        const SALES_TAGS = [
                            { tag: "OrderSummary", value: _orderSummaryTag },
                            { tag: "ShopName", value: shopData?.name },
                            { tag: "PickUpPlace", value: orderExtrasData?.customFields?.pickUp?.location },
                            { tag: "DeliveryHourAndDate", value: deliveryHourAndDate(orderData, texts, true) },
                        ];
                        _msgbody = screenUtils.replaceSpecialTags(_msgbody, SALES_TAGS);
                        // insert in innerHTML
                        msg.message.innerHTML = _msgbody;
                        msg.message.title = replaceBodyTags(msg.message.title, {
                            shopName: shopData?.name,
                            pickUp: orderExtrasData?.customFields?.pickUp?.location,
                            deliveryHourAndDate: deliveryHourAndDate(orderData, texts, true),
                        });
                        msg.message.title = screenUtils.replaceSpecialTags(msg.message.title, tags);
                        msg.message.body = replaceBodyTags(msg.message.body, {
                            shopName: shopData?.name,
                            pickUp: orderExtrasData?.customFields?.pickUp?.location,
                            deliveryHourAndDate: deliveryHourAndDate(orderData, texts, true),
                        });
                        msg.message.information.parsed = true;
                        msgReplaced.current++;
                    }
                }
            }
        });
        if (msgReplaced.current >= replaceMsgTo.current) {
            dispatch(setMessages(messages));
            setTimeout(() => {
                rdyCallBack(true);
            }, 1000);
        }
    });
};

const deliveryHourAndDate = (_orderData, texts, removeDivTag = false) => {
    let _hourAndDate = `<div>${texts["As soon as possible"]}</div>`;
    if (removeDivTag) {
        _hourAndDate = texts["As soon as possible"];
    }
    if (_orderData?.customFields?.scheduleStart && _orderData?.customFields?.scheduleEnd) {
        _hourAndDate = `${removeDivTag ? "" : "<div>"}${new Date(
            _orderData.customFields.scheduleStart,
        ).toLocaleDateString([], {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
        })}: ${new Date(_orderData?.customFields?.scheduleStart).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        })} - ${new Date(_orderData?.customFields?.scheduleEnd).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        })}${removeDivTag ? "" : "</div>"}`;
    } else if (_orderData?.customFields?.scheduleStart) {
        _hourAndDate += `${texts["next-delivery-time"].replace(
            "{{time}}",
            `${new Date(_orderData.customFields.scheduleStart).toLocaleDateString([], {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
            })}: ${new Date(_orderData?.customFields?.scheduleStart).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
            })}`,
        )}`;
    }
    return _hourAndDate;
};

const replaceBodyTags = (text, TAGS) => {
    let _text = text.replaceAll("{{.ShopName}}", TAGS.shopName);
    _text = _text.replaceAll("{{.DeliveryHourAndDate}}", TAGS.deliveryHourAndDate);
    return _text.replaceAll("{{.PickUpPlace}}", TAGS.pickUp);
};

export default ParseSalesMessagesTags;
