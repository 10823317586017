import { memo, useEffect } from "preact/compat";
import focus from "../utils/focus";
import { printCenteredMessage } from "../utils/utils";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { STB } from "../hooks/apis/stb";
import Limbo from "./screen/limbo";
import { Texts } from "../utils/texts";

const ConnectionError = () => {
    const texts = useSelector((state) => state.ui.texts);
    const serverOnline = useSelector((state) => state.status.serverOnline);
    const connectionLost = useSelector(
        (state) => state.status?.locationData?.projectInfo?.highAvailabilityConfig?.messages?.connectionLost,
    );

    useEffect(() => {
        if (!serverOnline && STB.supportHA) {
            focus.value.replace("limbo");
            setTimeout(() => {
                STB.gotoOfflineMode();
            }, 3000);
        }
    }, [serverOnline]);

    if (serverOnline || !STB.supportHA) {
        return null;
    }

    return (
        <>
            <Limbo fullBlock={true} />
            <div
                className={"w-full"}
                style={{
                    height: "100vh",
                    zIndex: "9999",
                    position: "relative",
                    fontFamily: "Lato",
                    fontWeight: "bold",
                    background: "linear-gradient(0deg, #373D42 0%, rgba(55, 61, 66, 0.62) 51.08%, #373D42 100%)",
                }}
            >
                {printCenteredMessage(
                    ReactHtmlParser(
                        `<span style='font-size:2vw;'>${
                            connectionLost || Texts.translateCap(texts, "no-connection")
                        }</span>`,
                    ),
                    null,
                    "",
                    "white",
                )}
            </div>
        </>
    );
};

export default memo(ConnectionError);
