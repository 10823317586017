import { useEffect } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Texts } from "../../utils/texts";
import { displayErrorPopUp, showLoading } from "../../actions/uiActions";
import { STB } from "../../hooks/apis/stb";

const Reload = () => {
    const texts = useSelector((state) => state.ui.texts);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(displayErrorPopUp({ text: Texts.capitalize(texts["reload-info"]), timeout: 6000 }));
        setTimeout(function () {
            dispatch(showLoading(true));
            STB.fullreload();
        }, 5000);
    }, []);

    return null;
};

export default Reload;
