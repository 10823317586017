/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";

import { STB } from "../hooks/apis/stb";
import Query from "../hooks/query";
import { Texts } from "../utils/texts";
import Modal from "./common/modal";
import VerticalList from "./common/verticalList";
import localeCode from "locale-code";
import { composeLocaleCode, inPreview } from "../utils/utils";
import focus from "../utils/focus";
import { setPopUp } from "../actions/uiActions";

const SelectAppLanguage = () => {
    const dispatch = useDispatch();

    //Store data
    const sessionData = useSelector((state) => state.status.sessionData);
    const locationData = useSelector((state) => state.status.locationData);
    const keysHistory = useSelector((state) => state.events.keysHistory);
    const texts = useSelector((state) => state.ui.texts);
    const [activeLang, setActiveLang] = useState();

    let langs = [];
    let firstToFocus = null;

    locationData.projectInfo?.langs.map((pLang, index) => {
        langs.push({
            id: pLang.languageRef,
            name: Texts.capitalize(localeCode.getLanguageNativeName(composeLocaleCode(pLang.languageRef))),
        });
        if (index === 0) {
            firstToFocus = pLang.languageRef;
        }
    });

    //Listeners
    useLayoutEffect(() => {
        //Pre select the guest language if we have it
        let langToFocus = localStorage.getItem("lang");
        if (
            !langToFocus &&
            sessionData &&
            sessionData.stayguest &&
            sessionData.stayguest.languageRef &&
            document.querySelector(`#btn-${sessionData.stayguest.languageRef}`)
        ) {
            langToFocus = sessionData.stayguest.languageRef;
        }
        if (!document.querySelector(`#btn-${langToFocus}`) && firstToFocus) {
            focus.value.replace(`btn-${firstToFocus}`);
        } else {
            focus.value.replace(`btn-${langToFocus}`);
            setActiveLang(langToFocus);
        }
    }, []);

    //Listen keysHistory to show hidden Zafiro Menu
    useEffect(() => {
        if (
            keysHistory?.length > 3 &&
            keysHistory[0]?.value === "right" &&
            keysHistory[1]?.value === "left" &&
            keysHistory[2]?.value === "right" &&
            keysHistory[3]?.value === "left"
        ) {
            if (new Date(keysHistory[0].time).getTime() - new Date(keysHistory[3].time).getTime() < 2000) {
                dispatch(setPopUp("zafiroMenu"));
            }
        }
    }, [keysHistory]);

    ////////////////////////
    //Functions
    ///////////////////////

    //Query to set user lang
    const onLangClick = (val) => {
        Query({
            query: `
                    mutation{ 
                        changeTVLang (language:"${val}") 
                        {
                            error 
                            {
                                code 
                                msg
                            } 
                            ok
                            token
                            stayToken
                        }
                    }
              `,
            onResponse(res) {
                localStorage.setItem("lang", val);
                const newToken = res?.data?.changeTVLang?.token;
                const stayToken = res?.data?.changeTVLang?.stayToken;
                const newLang = val;
                if (inPreview() && newToken) {
                    window.location =
                        window.location.origin + `?lang=${newLang}&token=${newToken}&stayToken=${stayToken}`;
                } else {
                    STB.reload();
                }
            },
        });
    };

    return (
        <>
            <Modal
                title={Texts.capitalize(texts["select-language"])}
                style={{ noPadding: true }}
                backBtn={true}
                body={
                    <VerticalList
                        insideModal={true}
                        preventFirstFocus={true}
                        activeItemId={`${activeLang}`}
                        data={{
                            list: langs,
                            onClick: onLangClick,
                            customClass: "text-center text-base",
                        }}
                    />
                }
            />
        </>
    );
};

export default SelectAppLanguage;
