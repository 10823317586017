import { useDispatch, useSelector } from "react-redux";
import { Texts } from "../../utils/texts";
import focus from "../../utils/focus";
import { figmapx2vh, inPreview } from "../../utils/utils";
import { addFavourite, deleteFavourite } from "../../actions/statusActions";
import { memo, useMemo } from "react";
import { displayErrorPopUp } from "../../actions/uiActions";

const ChannelFavouriteSelector = ({ playingChannel }) => {
    const dispatch = useDispatch();

    //States
    const favouriteChannelIDs = useSelector((state) => state.status.locationData.deviceInfo.favouriteChannelIDs);

    //Store data
    const texts = useSelector((state) => state.ui.texts);

    const isFocused = (id) => {
        return focus.value.current === id;
    };

    const setFavourite = () => {
        if (inPreview()) {
            dispatch(displayErrorPopUp({ text: texts["preview-msg"], timeout: 3000 }));
            return;
        }
        if (!favouriteChannelIDs || favouriteChannelIDs.indexOf(Number(playingChannel.id)) === -1) {
            dispatch(addFavourite(playingChannel.id));
        } else {
            dispatch(deleteFavourite(playingChannel.id));
        }
    };
    const isFavourite = favouriteChannelIDs.includes(playingChannel?.id);

    return useMemo(() => {
        return (
            <div
                id="btn-my-list"
                onClick={() => setFavourite()}
                tabIndex={0}
                focusClass="channelList-focus"
                className={`icon icon-${isFavourite ? "like" : "unlike"} ${
                    isFocused("btn-my-list") ? "channelList-focus" : ""
                } relative navigable`}
                style={{ lineHeight: `${figmapx2vh(80)}` }}
            >
                <div className={`lato ${isFocused("btn-my-list") ? "iconNameFocused" : "hidden"}`}>
                    {Texts.capitalize(texts["my list"])}
                </div>
            </div>
        );
    });
};

export default memo(ChannelFavouriteSelector);
