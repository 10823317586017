/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "preact/hooks";
import { cleanWhiteChars, figmapx2vh, figmapx2vw } from "../../utils/utils";
import { memo } from "preact/compat";
import { Theme } from "../../utils/theme";
import focus from "../../utils/focus";

const Button = ({ data, component, insideModal }) => {
    let {
        id,
        name,
        value,
        icon,
        iconPosition,
        faIcon,
        iconSize,
        imageURL,
        customClass,
        customStyle,
        wrapperClass,
        focusClass,
        body,
        innerHTML,
        bodyClass,
        onClick,
        outline,
        border,
        rounded,
        keysBlockedOnScroll,
        preventScrollOnFocus,
        removeButtonClass,
        customAttribute,
        active,
        disabled,
    } = data;
    const btnId = cleanWhiteChars(`btn-${typeof id !== "undefined" ? id : name}`);

    if (insideModal) {
        customStyle = customStyle || {};
        if (!customStyle.borderColor) {
            customStyle.borderColor = Theme.light ? Theme.colors.default.gray["300"] : Theme.colors.default.gray["600"];
            if (border) {
                customStyle.borderWidth = `0.1vw`;
                customStyle.borderStyle = `solid`;
            }
            if (active && outline) {
                customStyle.borderColor = Theme.colors.activeBackground;
                customStyle.borderWidth = `0.3vw`;
            }
        }
        if (!customStyle.backgroundColor) {
            customStyle.backgroundColor = "transparent";
        }
        if (!customStyle.color) {
            customStyle.color = Theme.light ? Theme.colors.default.gray["800"] : Theme.colors.default.gray["100"];
        }
    }

    //Listeners
    useEffect(() => {
        if (innerHTML) {
            document.querySelector(`#btn-body-${btnId}`).innerHTML = innerHTML;
        }
    }, [innerHTML]);

    const clickButton = () => {
        if (onClick) {
            onClick(value);
        }
    };

    const renderButtonBody = () => {
        return (
            <div
                id={btnId}
                itemId={id}
                customattribute={customAttribute}
                data-keysBlockedOnScroll={keysBlockedOnScroll?.join("-")}
                data-prevent-scroll-on-focus={preventScrollOnFocus}
                tabIndex={0}
                focusClass={focusClass}
                //prettier-ignore
                className={`${!disabled?'navigable':''} table ${rounded ? "rounded" : ""} ${customClass ? customClass : " w-full py-4"} ${border && !insideModal ? `borderColor border` : ""} ${
                    focus.value.current === btnId
                        ? focusClass? focusClass : outline
                            ? "focusOutline"
                            : "btnFocused"
                        : active &&'active'
                } ${removeButtonClass ||'button'} ${outline ? 'btn-focusOutline':''} `}
                style={{
                    ...customStyle,
                    backgroundColor: disabled ? Theme.colors.default.gray["300"] : customStyle?.backgroundColor,
                    color: disabled ? Theme.colors.default.gray["700"] : customStyle?.color,
                }}
                onClick={(e) => clickButton()}
            >
                {/*`${renderCount.current++} time(s)`*/}
                {body || innerHTML || component ? (
                    <div id={`btn-body-${btnId}`} style={{ height: "inherit" }} className={bodyClass}>
                        {body || component}
                    </div>
                ) : (
                    <div className="table-cell vertical-middle">
                        {icon || faIcon ? (
                            <span
                                className={`${icon ? `icon icon-${icon}` : ""} ${
                                    faIcon ? `${faIcon.lib} fa-${faIcon.name} pb-6` : ""
                                }  ${name ? "pr-2 pt-1" : ""} ${iconSize || "text-2xl"}`}
                            />
                        ) : null}
                        {imageURL ? <img className={"mx-auto pb-6 block w-1/3"} src={imageURL} /> : null}
                        {faIcon || imageURL || iconPosition === "top" ? (
                            <div className="">{name}</div>
                        ) : (
                            <span className="">{name}</span>
                        )}
                    </div>
                )}
            </div>
        );
    };
    return useMemo(() => {
        return (
            <>
                {focusClass === "underSlashFocus" ? (
                    <div className={` ${wrapperClass ? wrapperClass : ""}`}>
                        {renderButtonBody()}
                        <div
                            className={`mx-auto rounded ${
                                focus.value.current === btnId && focusClass === "underSlashFocus" ? "" : "invisible"
                            }`}
                            style={{
                                marginTop: "1vh",
                                backgroundColor: "#BDC8D3",
                                width: figmapx2vw(104),
                                height: figmapx2vh(5),
                            }}
                        ></div>
                    </div>
                ) : (
                    renderButtonBody()
                )}
            </>
        );
    }, [data, active, focus.value.current, disabled]);
};

export default memo(Button);
